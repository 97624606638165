<template>
  <v-app id="inspire">
    <nav-bar/>

    <v-main class="grey lighten-3">
      <v-container>
        <v-row>
          <v-col>
            <v-sheet
              min-height="70vh"
              rounded="lg"
              class="pa-5"
            >
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import NavBar from "@/components/NavBar";

export default {
  components: {
    NavBar
  }
}
</script>
